import React, { Fragment } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import Switch from "@material-ui/core/Switch";
// Mis Componentes
import SelectInput from "components/shared/SelectInput";
import Datepicker from "components/shared/Datepicker";
import ErrorFeedback from "components/shared/ErrorFeedback";
// Mis Types
import { Chofer } from "models/Choferes";
import { Option } from "typings/General";

interface DetallesAdicionalesProps {
  chofer: Chofer;
  hasRentaSemanal: boolean;
  diasCorte: Option[];
  tiposLicencia: Option[];
  bancos: Option[];
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBackBtnClick: () => void;
  onStepSubmit: (values, actions) => void;
}

const DetallesAdicionales: React.FC<DetallesAdicionalesProps> = ({
  chofer,
  hasRentaSemanal,
  diasCorte,
  tiposLicencia,
  bancos,
  onSwitchChange,
  onBackBtnClick,
  onStepSubmit,
}) => {
  const initialValues = {
    // Si el chofer es tipo Taxista y tiene renta semanal activado, se agregan estos campos
    ...(chofer.idTipoChofer === 1 && hasRentaSemanal
      ? {
        diaCorte: chofer.diaCorte,
        montoRenta: chofer.montoRenta,
        recargo: chofer.recargo,
      }
      : {}),
    prefijoClave: chofer.prefijoClave,
    codigoClave: chofer.codigoClave,
    birthDate: chofer.birthDate,
    CURP: chofer.CURP,
    RFC: chofer.RFC,
    tipoSangre: chofer.tipoSangre,
    idTipoLicencia: chofer.idTipoLicencia,
    licencia: chofer.licencia,
    expireDateLicencia: chofer.expireDateLicencia,
    polizaSeguro: chofer.polizaSeguro,
    polizaSeguroExpireDate: chofer.polizaSeguroExpireDate,
    numeroCuenta: chofer.numeroCuenta,
    idBanco: chofer.idBanco,
    CLABE: chofer.CLABE,
    calle: chofer.calle,
    numero: chofer.numero,
    colonia: chofer.colonia,
    cp: chofer.cp,
  };
  const detallesAdicionalesSchema = Yup.object().shape({
    // Si el chofer es tipo Taxista y tiene renta semanal activado, se agregan estos campos
    ...(chofer.idTipoChofer === 1 && hasRentaSemanal
      ? {
        diaCorte: Yup.number().nullable().required("Campo requerido"),
        montoRenta: Yup.number()
          .typeError("Solo números")
          .required("Campo requerido")
          .moreThan(0, "Debe ser mayor a 0. Ej: 0.20, 200, etc"),
        recargo: Yup.number().typeError("Solo números").required("Campo requerido").moreThan(0, "Debe ser mayor a 0. Ej: 0.20, 200, etc"),
      }
      : {}),
    prefijoClave: Yup.string().matches(/^[A-Z]{2,3}$/, "Solo letras mayúsculas. De 2 a 3 letras."),
    codigoClave: Yup.string()
      .required("Campo requerido")
      .matches(/^\d{1,3}$/, "Solo números. De 1 a 3 digitos"),
    birthDate: Yup.string().matches(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/, "Fecha inválida"),
    CURP: Yup.string().matches(
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      "Ingrese una CURP válida"
    ),
    RFC: Yup.string().matches(
      /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/,
      "Ingrese un RFC válido"
    ),
    tipoSangre: Yup.string().matches(/^(A|B|AB|O)[-+]$/, "Ingrese tipo de sangre válido. Ej: A+, B+, AB+, O+, etc"),
    idTipoLicencia: Yup.number().required("Campo requerido"),
    licencia: Yup.string().required("Campo requerido"),
    expireDateLicencia: Yup.string()
      .matches(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/, "Fecha inválida")
      .required("Campo requerido"),
    polizaSeguro: Yup.string().required("Campo requerido"),
    polizaSeguroExpireDate: Yup.string()
      .matches(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/, "Fecha inválida")
      .required("Campo requerido"),
    numeroCuenta: Yup.number().typeError('Solo números'),
    /*.string().matches(
       /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|6[27][0-9]{14})$/,
       "Ingrese un número de tarjeta válido. Deben ser 16 dígitos"
    ),*/
    banco: Yup.number().nullable(),
    CLABE: Yup.string().matches(/^\d{18}$/, "Ingrese una clave válida. Deben ser 18 dígitos"),
    calle: Yup.string(),
    numero: Yup.number().typeError("Solo números"),
    colonia: Yup.string(),
    cp: Yup.string().matches(/^\d{4,5}$/, "Ingrese un Codigo Postal válido"),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={detallesAdicionalesSchema}
      onSubmit={onStepSubmit}
      render={({ setFieldValue }: FormikProps<any>) => (
        <Form>
          {/* {chofer.idTipoChofer === 1 && (
            <Fragment>
              <p className="text-muted font-size-18">Renta de vehículo</p>
              <p className="mb-0">
                ¿Renta semanal?
                <span className="font-weight-medium ml-4">
                  No
                  <Switch color="primary" onChange={onSwitchChange} checked={hasRentaSemanal} />
                  Sí
                </span>
              </p>
              {hasRentaSemanal && (
                <MDBRow className="mb-4">
                  <MDBCol md="4">
                    <Field
                      name="diaCorte"
                      render={({ field }) => (
                        <SelectInput
                          {...field}
                          placeholder="Dia de la semana de corte"
                          options={diasCorte}
                          isSearchable={false}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    />
                    <ErrorMessage name="diaCorte" component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md="4">
                    <Field
                      name="montoRenta"
                      render={({ field }) => (
                        <MDBInput {...field} icon="dollar-sign" outline label="Monto de la renta semanal" type="text" />
                      )}
                    />
                    <ErrorMessage name="montoRenta" component={ErrorFeedback} />
                  </MDBCol>
                  <MDBCol md="4">
                    <Field
                      name="recargo"
                      render={({ field }) => (
                        <MDBInput {...field} icon="dollar-sign" outline label="Recargo por día atrasado" type="text" />
                      )}
                    />
                    <ErrorMessage name="recargo" component={ErrorFeedback} />
                  </MDBCol>
                </MDBRow>
              )}

              <hr />
            </Fragment>
          )} */}

          <p className={`text-muted ${chofer.idTipoChofer === 1 ? "mt-4" : ""} font-size-18`}>Información de chofer</p>
          <MDBRow className="mb-3">
            <MDBCol md="4">
              <Field
                name="prefijoClave"
                render={({ field }) => (
                  <MDBInput {...field} outline label="Prefijo Clave" type="text" disabled={chofer.idTipoChofer !== 2} />
                )}
              />
              <ErrorMessage name="prefijoClave" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field name="codigoClave" render={({ field }) => <MDBInput {...field} outline label="Código Clave" type="text" />} />
              <ErrorMessage name="codigoClave" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field
                name="birthDate"
                render={({ field }) => <Datepicker {...field} labelText="Fecha de nacimiento" setFieldValue={setFieldValue} />}
              />
              <ErrorMessage name="birthDate" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-3">
            <MDBCol md="4">
              <Field name="CURP" render={({ field }) => <MDBInput {...field} outline label="CURP" type="text" />} />
              <ErrorMessage name="CURP" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field name="RFC" render={({ field }) => <MDBInput {...field} outline label="RFC" type="text" />} />
              <ErrorMessage name="RFC" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field name="tipoSangre" render={({ field }) => <MDBInput {...field} outline label="Tipo de sangre" type="text" />} />
              <ErrorMessage name="tipoSangre" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-3">
            <MDBCol md="4">
              <Field
                name="idTipoLicencia"
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    placeholder="Tipo de licencia"
                    options={tiposLicencia}
                    isSearchable={false}
                    setFieldValue={setFieldValue}
                  />
                )}
              />
              <ErrorMessage name="idTipoLicencia" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field name="licencia" render={({ field }) => <MDBInput {...field} outline label="Licencia" type="text" />} />
              <ErrorMessage name="licencia" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field
                name="expireDateLicencia"
                render={({ field }) => <Datepicker {...field} labelText="Fecha de vencimiento" setFieldValue={setFieldValue} />}
              />
              <ErrorMessage name="expireDateLicencia" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mb-3">
            <MDBCol md="4">
              <Field name="polizaSeguro" render={({ field }) => <MDBInput {...field} outline label="Poliza seguro" type="text" />} />
              <ErrorMessage name="polizaSeguro" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol md="4">
              <Field
                name="polizaSeguroExpireDate"
                render={({ field }) => <Datepicker {...field} labelText="Fecha de vencimiento" setFieldValue={setFieldValue} />}
              />
              <ErrorMessage name="polizaSeguroExpireDate" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-4">
            <MDBCol>
              <Field
                name="numeroCuenta"
                render={({ field }) => <MDBInput {...field} outline label="Número de cuenta bancaria" type="text" />}
              />
              <ErrorMessage name="numeroCuenta" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol>
              <Field
                name="idBanco"
                render={({ field }) => (
                  <SelectInput {...field} placeholder="Banco" options={bancos} isSearchable={false} setFieldValue={setFieldValue} />
                )}
              />
              <ErrorMessage name="idBanco" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol>
              <Field name="CLABE" render={({ field }) => <MDBInput {...field} outline label="CLABE" type="text" />} />
              <ErrorMessage name="CLABE" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <hr />

          <p className="text-muted mt-4 font-size-18">Información de domicilio</p>
          <MDBRow className="mb-3">
            <MDBCol>
              <Field name="calle" render={({ field }) => <MDBInput {...field} outline label="Calle" type="text" />} />
              <ErrorMessage name="calle" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol>
              <Field name="numero" render={({ field }) => <MDBInput {...field} outline label="Número de casa" type="text" />} />
              <ErrorMessage name="numero" component={ErrorFeedback} />
            </MDBCol>
            <MDBCol>
              <Field name="colonia" render={({ field }) => <MDBInput {...field} outline label="Colonia" type="text" />} />
              <ErrorMessage name="colonia" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-4">
            <MDBCol md="4">
              <Field name="cp" render={({ field }) => <MDBInput {...field} outline label="Código postal" type="text" />} />
              <ErrorMessage name="cp" component={ErrorFeedback} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-5 justify-content-center">
            <MDBCol md="4">
              <MDBBtn outline className="font-weight-medium mx-0 px-5 text-capitalize" block onClick={onBackBtnClick}>
                Atrás
              </MDBBtn>
            </MDBCol>
            <MDBCol md="4">
              <MDBBtn color="default" type="submit" className="font-weight-medium mx-0 px-5" block>
                Siguiente
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </Form>
      )}
    />
  );
};

export default DetallesAdicionales;

